import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  data() {
    return {
      //randuniq:
    };
  },
  mounted() {
    this.initializeMatomo();
  },
  methods: {
    initializeMatomo() {
      var _paq = (window._paq = window._paq || []);
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      // _paq.push(["setUserId", stores.randuniq]);
      (function () {
        var u = "https://track.innocean.app/";
        _paq.push(["setTrackerUrl", u + "matomo.php"]);
        _paq.push(["setSiteId", "28"]);
        var d = document,
          g = d.createElement("script"),
          s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = u + "matomo.js";
        s.parentNode.insertBefore(g, s);
      })();
    },
  },
  render: (h) => h(App),
}).$mount("#app");
