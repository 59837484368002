//WATCH LE CHAMPS CODE POSTAL
export async function input_zipcodeConcess(newValue, select_concess) {
  let input_selectconcess = "";
  let errorAPI = false;
  let select_city = [];
  let input_selectcity = "";
  let displayMap = false;

  //SI L'INPUT CONTIENT BIEN 5 CHIFFRES, ON LANCE LA RECHERCHE DE VILLE
  if (newValue !== undefined && newValue.length === 5) {
    var requestOptions = { method: "GET", redirect: "follow" };
    let response = await fetch(
      "https://data.opendatasoft.com/api/explore/v2.1/catalog/datasets/geonames-postal-code@public/records?limit=20&refine=postal_code%3A" +
        newValue +
        "&refine=country_code%3A%22FR%22",
      requestOptions
    );
    response = await response.json();

    //SI PAS DE VILLE TROUVEE ON AFFICHE LE CHAMPS TEXT CLASSQUE POUR LA VILLE
    if (response.results.length === 0) {
      errorAPI = true;
      input_selectcity = "";
    } else {
      errorAPI = false;
      response.results.forEach((element) => {
        if (element.place_name) {
          select_city.push({
            nom_de_la_commune: element.place_name,
            coordonnees_geographiques: [element.coordinates],
          });
        }
      });
      input_selectcity = await response.results[0].place_name;

      //SI CODESAP PAS VALID ON TRIE LES CONCESSIONS PAR DISTANCES
      if (!displayMap) {
        let lat = await response.results[0].coordinates.lat;
        let lng = await response.results[0].coordinates.lon;
        console.log("lat", lat);
        const OrderedArr = reordonnerParDistance(select_concess, lat, lng);

        input_selectconcess = await select_concess[0].sap;
      }
    }
  }

  if (input_selectconcess && input_selectcity && !errorAPI && select_city) {
    return [input_selectconcess, select_city, input_selectcity, errorAPI];
  } else {
    input_selectcity = "";
    input_selectconcess = "";
    errorAPI = true;
    select_city = "";

    return [input_selectconcess, select_city, input_selectcity, errorAPI];
  }
}

// Fonction de réordonnancement du tableau d'objets en fonction de la distance au point spécifié
export function reordonnerParDistance(tableauPoints, latitude, longitude) {
  // Tri du tableau en utilisant la fonction de comparaison par distance
  tableauPoints.sort((pointA, pointB) => {
    const distanceA = getDistanceFromLatLonInKm(
      latitude,
      longitude,
      pointA.lat,
      pointA.lng
    );

    const distanceB = getDistanceFromLatLonInKm(
      latitude,
      longitude,
      pointB.lat,
      pointB.lng
    );
    return distanceA - distanceB;
  });
  return tableauPoints;
}

function getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2, name) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return (deg * Math.PI) / 180;
}
