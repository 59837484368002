export async function modeleVehicle(newValue, selectoption, mainUrl, bloclist) {
  return new Promise((resolve, reject) => {
    let select_clearcutSrc = "";
    let select_offreSrc = "";
    let Array = [];
    let start1time = 0;
    let transiEl = document.querySelector(".select__offre__transi");

    console.log("newValue", newValue);
    transiEl.addEventListener("transitionend", () => {
      start1time = start1time + 1;
      if (start1time === 1) {
        for (let i = 0; i < selectoption.length; i++) {
          if (selectoption[i].list) {
            if (selectoption[i].list[0].slug === newValue) {
              // CHANGEMENT URL CLEARCUT

              if (typeof selectoption[i].list[0].images[0] === "string") {
                select_clearcutSrc =
                  "https://proxy.hyundai.media/i/?blocoffre=" +
                  selectoption[i].list[0].images[0].path;
              } else {
                select_clearcutSrc =
                  "https://proxy.hyundai.media/i/?blocoffre=" +
                  selectoption[i].list[0].images[0].path;
              }
              Array.push(select_clearcutSrc);
            }
          } else if (selectoption[i]) {
            if (selectoption[i].slug === newValue) {
              // CHANGEMENT URL CLEARCUT
              if (typeof selectoption[i].images[0] === "string") {
                select_clearcutSrc =
                  "https://proxy.hyundai.media/i/?blocoffre=" +
                  selectoption[i].images[0].path;
              } else {
                select_clearcutSrc =
                  "https://proxy.hyundai.media/i/?blocoffre=" +
                  selectoption[i].images[0].path;
              }
              Array.push(select_clearcutSrc);
            }
          }
        }

        bloclist.forEach((item) => {
          if (item.title === "offre_" + newValue + ".svg") {
            // CHANGEMENT URL BLOC OFFRE
            select_offreSrc =
              "https://proxy.hyundai.media/i/?blocoffre=" + item.path;
            Array.push(select_offreSrc);
          }
        });
        // Renvoyer le tableau une fois qu'il est rempli
        if (Array.length === 2) {
          resolve(Array);
        }
      }

      // ANIMATION RIDEAU
      transiEl.classList.add("transiEnd");

      transiEl.addEventListener("transitionend", () => {
        start1time = start1time + 1;
        transiEl.classList.remove("transiEnd");
        transiEl.classList.remove("transiActive");
      });
    });
    transiEl.classList.add("transiActive");
  });
}
