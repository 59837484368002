import { defineStore } from "pinia";

export const useApiStore = defineStore("api", {
  state: () => ({
    baseUrl: "https://proxy.hyundai.media",
    Type: "m561p8rcr8gtvtz",
    Scenario: "m9s3myan8c8fsoi",
    Modeles: "min2pj4lk2rkc4u",
    Offers: "mp94zseuouyidih",
    ML: "mr4ydlmwqyvw6nk",
    Forms: "mfhc0it54s0hshi",
    Galerie: "m6qhzbfzqqyy6mw",
    Dealer: "mh675bmqtk535dh",
    MegaLP: "pir6cq65kvat4sx",
    xcToken: "BD@hpC3t7@xMJbcj839tj",
  }),
});
