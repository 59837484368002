const year = new Date().getFullYear();
const month = new Date().getMonth() + 1;

function DateTest(){
    return year + '/' + (month < 10 ? '0' + month : month)
}


export default {
    mainUrl: 'https://proxy.data.hyundai.media/',
    apiUrl: 'https://api.data.hyundai.media/',
    xApiKey: 'M5qq8et5jehBMrRQrisEF',
    randuniq: Date.now().toString(36) + (Math.random() + 1).toString(36),
    //mainUrl: 'http://localhost:8080/',
    slug_mention:'',
    formattedDate: DateTest(),
    bloclist: [],
    mllist:'',
    mentions: [],
    pl: []
}