<template>
  <div class="confirm">
    <div class="confirm__content">
      <p v-if="message">{{ message }}</p>
      <p v-else>Le formulaire a bien été envoyé&nbsp;!</p>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",

  props: {
    message: String,
    image: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

@keyframes confirm-swipe {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.confirm {
  position: fixed;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--MainBlue);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1200ms cubic-bezier(0.7, 0, 0, 1);

  &__content {
    width: 100%;
    p {
      text-align: center;
      color: var(--white);
      font-size: 24px;
      position: relative;
      width: 100%;
      padding: 0 0 0px 0;
      @media screen and (max-width: $maxMobile) {
        font-size: 20px;
      }
    }
  }
}

.active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
</style>
