<template>
  <div class="loader">
    <div class="loader__content">
      <p class="loader__content__number">{{ loadervalue }}%</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",

  data() {
    return {
      loadervalue: "0",
    };
  },

  mounted() {
    this.Animpercent();
  },

  methods: {
    Animpercent() {
      const duration = 600; // Animation duration in milliseconds
      const startTime = Date.now();
      const startWidth = 0;
      const endWidth = 100;
      const animate = () => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        if (elapsed >= duration) {
          this.loadervalue = endWidth;
          return;
        }
        const progress = elapsed / duration;
        const currentWidth = startWidth + progress * (endWidth - startWidth);
        this.loadervalue = Math.floor(currentWidth);
        requestAnimationFrame(animate);
      };
      requestAnimationFrame(animate);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/variables.scss";

@keyframes loader-line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes loader-swipe {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--MainBlue);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  animation: loader-swipe 1200ms cubic-bezier(0.7, 0, 0, 1) 600ms forwards;

  &__content {
    width: 100%;
    p {
      text-align: center;
      color: var(--white);
      font-size: 120px;
      position: relative;
      width: 100%;
      padding: 0 0 50px 0;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: var(--white);
        animation: loader-line 1500ms cubic-bezier(0.7, 0, 0.2, 1) forwards;
      }

      @media screen and (max-width: $maxMobile) {
        font-size: 80px;
      }
    }
  }
}
</style>
