export default function MLFormating(string) {
    var patterns = [];
    patterns[0] = /\ & /gm;
    patterns[1] = /\s+(?=TTC|:)/gm;
    patterns[2] = / \(\d\)/gm;
    patterns[3] = /((?! ).)(\(\d\))/gm;
    patterns[4] = /^Consommation.*\n/gm;
    patterns[5] = / \(\d\) /gm;
    patterns[6] = /(?!(<strong>))(([0-9]{1,3}(([ \r\n\t]|&nbsp;|)]?[0-9]{3}?)*( ?[k|m])?([\ ][0-9]{2})?\ ?(&euro;|€)(\ |))|(SANS APPORT([ \r\n\t]|&nbsp;|)(:|,)))(?!(<\/strong>))/gm;
    patterns[7] = /(?!(<strong><u>))((([0-9]|[0-2][0-9]|(3)[0-1])(\/)(([0-9]|(0)[0-9])|((1)[0-2]))(\/)\d{4})|((Modèle présenté)\ ?(&nbsp;:)))(?!(<\/u><\/strong>))/gm;
    patterns[8] = /CO2/gm;
    patterns[9] = /(?<=\d) +(?=\d)|(\s+(?=\:))/gm;
    patterns[10] = /(?!(<u>))(dans la limite des stocks disponibles)|( et sous réserve de toutes modifications et\/ou évolutions règlementaires)(?!(<\/u>))/gm;
    patterns[11] = /\n/gm;
    patterns[12] = /\s+(?=(mois|loyer|ans|km|kWh))/gm;
    patterns[13] = /(1)(er)/gm;
  
  
    var replacements = [];
    replacements[0] = ' &amp; ';
    replacements[1] = '&nbsp;';
    replacements[2] = '$2';
    replacements[3] = '$1<sup>$2</sup>';
    replacements[4] = '<strong>$&</strong>';
    replacements[5] = '<br/><br/>$2';
    replacements[6] = '<strong>$&</strong>';
    replacements[7] = '<strong><u>$&</u></strong>';
    replacements[8] = 'CO<sub>2</sub>';
    replacements[9] = '&nbsp;';
    replacements[10] = '<u>$2</u>';
    replacements[11] = '<br/>';
    replacements[12] = '&nbsp;';
    replacements[13] = '1<sup>$2</sup>';
  
    var result = string;
    for (var i = 0; i < patterns.length; i++) {
      result = result.replace(patterns[i], replacements[i]);
    }
  
    return result;
}