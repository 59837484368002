<template>
  <div class="map">
    <div class="row__map">
      <div class="mapheader">
        <span>{{ concessadress }}</span>
      </div>
      <div id="mapContainer"></div>
      <div class="map_loading">Chargement de la carte...</div>
    </div>
  </div>
</template>

<script>
import stores from "@/assets/js/store/store";
import mapboxgl from "mapbox-gl";
import { useApiStore } from "@/pinia/api";

export default {
  props: {
    displayMap: String,
    sapParam: String,
  },

  data() {
    return {
      api: useApiStore(),
      concessadress: "",
    };
  },

  mounted() {
    this.loadMap();
  },

  methods: {
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    isTodayEven() {
      const today = new Date();
      const day = today.getDate(); // Récupère le numéro du jour dans le mois
      return day % 2 === 0; // Vérifie si le jour est pair
    },

    loadMap() {
      let sapFalse = true;
      //PARAMETRAGE CODE SAP

      if (this.sapParam) {
        //RÉCUPÉRATION DE TOUTE LES CONCESSIONS DANS NOCODB
        var myHeaders = new Headers();
        myHeaders.append("xc-api-token", this.api.xcToken);
        myHeaders.append("x-table-id", this.api.Dealer);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          this.api.baseUrl + "?where=(sap,eq," + this.sapParam + "%29",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (!result.list[0].sap) {
              this.$emit("sapFalse");
            }
            //SI UN CODE SAP CORRESPOND
            if (result.list[0].sap === this.sapParam) {
              //AFFICHAGE DE MAP
              this.concessadress =
                result.list[0].streetAndNumber + " - " + result.list[0].city;
              mapboxgl.accessToken = this.isTodayEven()
                ? process.env.VUE_APP_API_MAPBOX_TOKEN_PAIR
                : process.env.VUE_APP_API_MAPBOX_TOKEN_IMPAIR;

              //mapbox://styles/mapbox/dark-v11
              //PARAMETRAGE MAP
              let isMobile = this.isMobileDevice();

              let longitude = parseFloat(result.list[0].lng);
              let latitude = parseFloat(result.list[0].lat);

              var map = new mapboxgl.Map({
                container: "mapContainer",
                style:
                  window.matchMedia &&
                  window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "mapbox://styles/mapbox/streets-v12"
                    : "mapbox://styles/mapbox/dark-v11",
                center: [longitude, latitude],
                zoom: 15,
                minZoom: 5,
                projection: "mercator",
                scrollZoom: !isMobile,
                dragRotate: !isMobile,
                touchZoomRotate: !isMobile,
                doubleClickZoom: !isMobile,
                dragPan: !isMobile,
              });

              //AJOUT DU MARKER
              const el = document.createElement("div");
              el.className = "markermap";
              let marker = new mapboxgl.Marker(el)
                .setLngLat([longitude, latitude])
                .addTo(map);

              //ANIMATION REVEAL MAP
              map.on("load", function () {
                document
                  .querySelector(".map_loading")
                  .classList.add("map_loaded");
              });
            }

            //SI AUCUNE CORRESPONDANCE
          })
          .catch((error) => console.log("error", error));
      }
      //SI PAS DE CODE SAP EN URL
      else {
        //FETCH DE LA LISTE DE TOUTES LE CONCESSIONS
        var myHeaders = new Headers();
        myHeaders.append("xc-api-token", this.api.xcToken);
        myHeaders.append("x-table-id", this.api.Dealer);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        fetch(
          this.api.baseUrl + "?sort=zip&limit=250&fields=zip,name,sap,lat,lng",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            this.select_concess = result.list;
          })
          .catch((error) => console.log("error", error));
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
