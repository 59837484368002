<template>
  <div class="form">
    <img
      :src="data.Maindata.formulaire[0].trackingForm"
      alt="tracking"
      class="tracking"
      v-if="image"
    />
    <form
      @submit="SubmitForm"
      method="POST"
      action="send/FormBasiqueError.php"
      id="formulaire"
      autocomplete="off"
    >
      <div class="title">
        <picture>
          <source :srcset="darklogo" media="(prefers-color-scheme:dark)" />
          <img
            class="home__formsection__logo"
            :src="logo"
            alt="Hyundai"
            width="auto"
            height="auto"
          />
        </picture>
        <h2 v-if="!error">{{ data.Maindata.formulaire[0].titleForm }}</h2>
        <h3 v-if="!error">{{ data.Maindata.formulaire[0].subtitleForm }}</h3>
      </div>
      <div class="row">
        <fieldset class="offre">
          <select
            :class="{ selected: input_selectmodel !== '' }"
            id="js_input_selectmodel"
            v-model="input_selectmodel"
            name="car"
            class="vueverif"
          >
            <option value="" disabled selected>Sélectionner un véhicule</option>
            <option
              v-for="(item, index) in selectoption"
              :key="index"
              :value="item.slug"
              class="option"
              name="car"
            >
              {{ item.title }}
            </option>
          </select>

          <div
            :class="{ active: input_selectmodel !== '' }"
            class="select__offre"
          >
            <div class="select__offre__transi"></div>
            <div class="select__offre__right">
              <img
                v-if="select_clearcutSrc"
                :src="select_clearcutSrc"
                alt="hyundai"
                width="auto"
                height="auto"
              />
            </div>
            <div class="select__offre__left">
              <img
                v-if="select_offreSrc"
                :src="select_offreSrc"
                alt="offre"
                width="auto"
                height="auto"
              />
              <span @click="DisplayML">Voir conditions</span>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <select
            :class="{ selected: input_selectgender !== '' }"
            id="js_input_selectgender"
            v-model="input_selectgender"
            name="civilite"
            class="vueverif"
          >
            <option selected disabled value="">Civilité</option>
            <option value="Mme">Madame</option>
            <option value="Mr">Monsieur</option>
          </select>
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_firstname !== '' }"
            for="js_input_firstname"
            name="firstname"
            >Prénom</label
          >
          <input
            class="vueverif"
            data-regex="^[a-zA-Z-' ]+$"
            v-model="input_firstname"
            id="js_input_firstname"
            name="firstname"
          />
        </fieldset>

        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_lastname !== '' }"
            for="js_input_lastname"
            name="lastname"
            >Nom</label
          >
          <input
            class="vueverif"
            data-regex="^[a-zA-Z-' ]+$"
            v-model="input_lastname"
            id="js_input_lastname"
            name="lastname"
          />
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_email !== '' }"
            for="js_input_email"
            name="email"
            >Email</label
          >
          <input
            class="vueverif"
            data-regex="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            v-model="input_email"
            id="js_input_email"
            name="email"
          />
        </fieldset>
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_tel !== '' }"
            for="js_input_tel"
            name="tel"
            >N° de téléphone</label
          >
          <input
            class="vueverif"
            data-regex="^(01|02|03|04|05|06|07|09) \d{2} \d{2} \d{2} \d{2}$"
            v-mask="'## ## ## ## ##'"
            v-model="input_tel"
            id="js_input_tel"
            name="tel"
            autocomplete="one-time-code"
          />
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_zipcode !== '' }"
            for="js_input_zipcode"
            name="zipcode"
            >Code postal</label
          >
          <input
            class="vueverif"
            data-regex="^\d{5}$"
            v-mask="'#####'"
            v-model="input_zipcode"
            id="js_input_zipcode"
            name="zipcode"
          />
        </fieldset>
        <fieldset v-if="!errorAPI">
          <select
            :class="{ selected: input_selectcity !== '' }"
            id="js_input_selectcity"
            v-model="input_selectcity"
            name="city"
            class="vueverif"
          >
            <option selected disabled value="">Ville</option>
            <option
              v-for="(item, index) in select_city"
              :key="index"
              :value="item.nom_de_la_commune"
            >
              {{ item.nom_de_la_commune }}
            </option>
          </select>
        </fieldset>

        <fieldset v-else>
          <label
            class="labelAnim"
            :class="{ activeLabel: input_selectcity !== '' }"
            for="js_input_selectcity"
            name="city"
            >Ville</label
          >
          <input
            class="vueverif"
            v-model="input_selectcity"
            id="js_input_selectcity"
            name="city"
          />
        </fieldset>
      </div>

      <div class="row">
        <fieldset class="offre">
          <select
            :class="{ selected: input_selectconcess !== '' }"
            id="js_input_selectconcess"
            v-model="input_selectconcess"
            name="concess"
            class="vueverif"
          >
            <option value="" disabled selected>
              Sélectionner un distributeur
            </option>
            <option
              v-for="(item, index) in select_concess"
              :key="index"
              :value="item.sap"
              v-if="item.zip"
            >
              {{ item.zip.substring(0, 2) }} - {{ item.name }}
            </option>
            <option
              v-for="(item, index) in select_concess"
              :key="index"
              :value="item.sap"
              v-if="!item.zip"
            >
              {{ item.name }}
            </option>
          </select>
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <label class="customcheckbox">
            Je souhaite recevoir des actualités de Hyundai Motor France et de
            son réseau de distributeurs et réparateurs agréés et être informé(e)
            des nouveaux produits, des services, des événements et des offres
            Hyundai.
            <details>
              <summary>En savoir plus</summary>
              <p>
                En cochant cette case vous acceptez que Hyundai Motor France et
                votre distributeur/réparateur préféré tel que sélectionné par
                vous utilisent vos données personnelles pour vous envoyer des
                communications et actualités concernant les produits, services,
                évènements et offres. Pour vous envoyer des communications, nous
                pouvons vous contacter par courrier électronique, téléphone,
                courrier et/ou services de messagerie. Hyundai Motor France et
                votre distributeur/réparateur préféré tel que sélectionné par
                vous, utilisent vos données personnelles pour vous adresser des
                communications.
              </p>
            </details>
            <input
              type="checkbox"
              id="js_input_news"
              v-model="input_news"
              name="optin"
              class="formlp"
            />
            <span class="customcheckbox__checkmark"></span>
          </label>
        </fieldset>
      </div>

      <div class="row">
        <fieldset>
          <label class="customcheckbox">
            Je souhaite recevoir des communications personnalisées basées sur
            mes préférences et mon utilisation des produits et services de
            Hyundai Motor France et de son réseau de distributeurs et
            réparateurs agrées. Pour cela, j'accepte que Hyundai Motor France
            crée mon profil client individuel.
            <details>
              <summary>En savoir plus</summary>
              <p>
                En cochant cette case, vous acceptez que Hyundai Motor France
                utilise vos données personnelles pour créer votre profil client
                individuel et vous envoie des communications adaptées à votre
                situation. Pour vous envoyer des communications personnalisées,
                nous pouvons vous contacter par courrier électronique,
                téléphone, courrier et/ou services de messagerie. Pour créer
                votre profil client individuel, nous combinerons vos préférences
                et intérêts avec des informations sur votre utilisation de nos
                produits et services, des informations sur la façon dont vous
                interagissez avec nos campagnes marketing, des informations
                socio-économiques ainsi que des informations sur votre
                utilisation de nos sites et applications et/ou votre utilisation
                de votre véhicule.
              </p>
            </details>
            <input
              type="checkbox"
              id="js_input_news2"
              v-model="input_news2"
              name="optin_NL"
              class="formlp"
            />
            <span class="customcheckbox__checkmark"></span>
          </label>
        </fieldset>
      </div>

      <div class="row">
        <p>
          En validant ce formulaire, je certifie être titulaire d'un permis de
          conduire de catégorie B valide et m'engage à respecter le code de la
          route lors de la prise en main.
        </p>
      </div>
      <div class="row">
        <button class="sendForm">
          <span>
            <span>{{
              this.error === true
                ? "Confirmer"
                : data.Maindata.formulaire[0].ctaForm
            }}</span>
          </span>
        </button>
      </div>
      <div class="row">
        <p>
          Je comprends que je peux modifier ou retirer mon consentement au
          traitement de mes données personnelles à tout moment.
          <span class="rgpdSpan" @click="DisplayRGPD()">En savoir plus.</span
          ><br /><br />
          Pour plus d'informations sur le traitement de mes données personnelles
          par Hyundai Motor France et sur mes droits, je peux me référer à la
          <a href="https://www.hyundai.com/fr/fr/rgpd.html" target="_blank"
            >Politique de protection des données personnelles</a
          >
          de Hyundai.
        </p>
      </div>
      <input type="hidden" :value="utmParteners" name="parteners" />
      <input type="hidden" :value="utmSource" name="source" />
      <input type="hidden" :value="randuniq" name="randuniq" />
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import VueMask from "v-mask";
Vue.use(VueMask);
import axios from "axios";

//JS
import FormValidation from "@/assets/js/FormValidation";
import stores from "@/assets/js/store/store";
import {
  input_zipcodeConcess,
  reordonnerParDistance,
} from "@/assets/js/store/concessDistance";
import { modeleVehicle } from "@/assets/js/store/modeleVehicle";
import { ToggleML, ToggleRGPD } from "@/assets/js/ToggleML";
import Manage from "@/assets/js/ManageForm";
import { useApiStore } from "@/pinia/api";

import logo from "@/assets/img/Hyundai_Logo.svg";
import darklogo from "@/assets/img/Hyundai_Logo_dark.svg";

export default {
  name: "FormBasiqueError",

  props: {
    data: Object,
    error: Boolean,
    scenario: String,
    utmSource: String,
    utmParteners: String,
    modele: String,
    postformValid: Boolean,
    randuniq: String,
    formParams: Object,
  },

  watch: {
    //WATCH LE CHAMPS MODEL
    async input_selectmodel(newValue, oldValue) {
      const selectedModel = this.selectoption.find(
        (model) => model.slug === newValue
      );

      this.mentions = selectedModel.slug;
      this.ArraySelectModele = await modeleVehicle(
        selectedModel.slug,
        this.selectoption,
        stores.mainUrl,
        stores.bloclist
      );
      this.select_clearcutSrc = this.ArraySelectModele[0];

      this.select_offreSrc = this.ArraySelectModele[1];
    },

    input_selectcity(newValue) {
      if (newValue !== "") {
        const selectedCity = this.select_city.find(
          (city) => city.nom_de_la_commune === newValue
        );
        if (selectedCity) {
          const lat = selectedCity.coordonnees_geographiques[0];
          const lng = selectedCity.coordonnees_geographiques[1];
          reordonnerParDistance(this.select_concess, lat, lng);
          this.input_selectconcess = this.select_concess[0].sap;
        }
      }
    },

    //WATCH LE CHAMPS CODE POSTAL
    async input_zipcode(newValue, oldValue) {
      this.ArrayInput = await input_zipcodeConcess(
        newValue,
        this.select_concess
      );
      this.input_selectconcess = this.ArrayInput[0];

      this.select_city = this.ArrayInput[1];
      this.input_selectcity = this.ArrayInput[2];
      this.errorAPI = this.ArrayInput[3];
      if (this.select_city.length >= 1) {
        this.select_city.forEach((city) => {
          if (city.nom_de_la_commune === this.formParams.city) {
            this.input_selectcity = city.nom_de_la_commune;
          }
        });
      }
    },
  },

  data() {
    return {
      api: useApiStore(),
      mentions: null,
      logo: logo,
      darklogo: darklogo,
      //CHAMPS FORMUALIRE
      input_selectmodel: "",
      input_email: "",
      input_tel: "",
      input_selectgender: "",
      input_firstname: "",
      input_lastname: "",
      input_zipcode: "",
      input_selectcity: "",
      input_news: "",
      input_news2: "",
      input_selectconcess: "",

      //AUTRE
      valideform: true,
      selectoption: [],
      select_clearcutSrc: "",
      select_offreSrc: "",
      select_concess: "",
      select_city: [],
      errorAPI: false,
      optionsCiv: ["Mr", "Mme"],
      value: "",
      image: false,
    };
  },

  mounted() {
    this.FetchData();
  },

  methods: {
    initParams() {
      if (this.formParams.gender === "Mme" || this.formParams.gender === "Mr") {
        this.input_selectgender = this.formParams.gender;
      }
      console.log(this.formParams);
      if (this.formParams.firstname !== undefined)
        this.input_firstname = this.formParams.firstname;
      if (this.formParams.lastname !== undefined)
        this.input_lastname = this.formParams.lastname;
      if (this.formParams.email !== undefined)
        this.input_email = this.formParams.email;
      if (this.formParams.phone !== undefined)
        this.input_tel = this.formParams.phone;
      if (this.formParams.cp !== undefined)
        this.input_zipcode = this.formParams.cp;
    },

    DisplayRGPD() {
      ToggleRGPD();
    },

    //LIS DE PARAMETRE MODEL DE L'URL POUR AFFICHER LE BON MODELE DANS LE SELECT
    InitSelect() {
      const model = Manage.ManageSelectModel();
      for (let i = 0; i < this.selectoption.length; i++) {
        if (this.selectoption[i].slug === model) {
          this.select_clearcutSrc =
            this.api.baseUrl + "/i?" + this.selectoption[i].images[0].path;
          this.input_selectmodel = model;
          stores.slug_mention = model;
        }
      }
    },

    DisplayML() {
      ToggleML();
      stores.slug_mention = this.mentions;
    },

    async FetchData() {
      //console.log(this.error);
      if (this.error === true) {
        axios
          .get(this.api.baseUrl, {
            headers: {
              "xc-api-token": this.api.xcToken,
              "x-table-id": this.api.Modeles,
            },
          })
          .then((res) => {
            //console.log(res.data.list);
            this.selectoption = res.data.list;

            // Appelez votre fonction InitSelect ici si nécessaire
            this.InitSelect();
          });
      } else {
        const modelelist = this.data.Maindata.formulaire[0].modeleForm;

        var myHeaders = new Headers();
        myHeaders.append("xc-api-token", this.api.xcToken);
        myHeaders.append("x-table-id", this.api.Modeles);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const fetchPromises = modelelist.map((item) =>
          typeof item === "object"
            ? fetch(
                `${this.api.baseUrl}?where=(slug,eq,${item[0]})`,
                requestOptions
              )
                .then((response) => response.json())
                .catch((error) => {
                  // Vous pouvez gérer les erreurs ici si nécessaire
                })
            : fetch(
                `${this.api.baseUrl}?where=(slug,eq,${item})`,
                requestOptions
              )
                .then((response) => response.json())
                .catch((error) => {
                  // Vous pouvez gérer les erreurs ici si nécessaire
                })
        );

        // Utilisez Promise.all pour attendre la fin de toutes les requêtes fetch
        Promise.all(fetchPromises)
          .then((results) => {
            // remove all the empty array
            results = results.filter((el) => el.list.length !== 0);

            // Toutes les requêtes fetch sont terminées ici, et les résultats sont dans le tableau "results"
            this.selectoption = results;
            console.log(this.selectoption);

            //console.log("Toutes les requêtes fetch sont terminées");

            // this.$nextTick est utilisé ici pour s'assurer que le DOM est mis à jour
            this.$nextTick(() => {
              let option = document.querySelectorAll(".option");
              //console.log(option);
              if (option.length === 1) {
                option[0].selected = true;
                this.input_selectmodel = option[0].value;
              }

              // Appelez votre fonction InitSelect ici si nécessaire
              this.InitSelect();
              this.selectModele();
            });
          })
          .catch((error) => {
            //console.log("Erreur lors de l'attente des requêtes fetch", error);
          });
      }

      //FETCH DE LA LISTE DE TOUTES LE CONCESSIONS
      var myHeaders = new Headers();
      myHeaders.append("xc-api-token", this.api.xcToken);
      myHeaders.append("x-table-id", this.api.Dealer);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        this.api.baseUrl + "?sort=zip&limit=250&fields=zip,name,sap,lat,lng",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.select_concess = result.list;
          this.initParams();
        })
        .catch((error) => console.log("error", error));
    },

    selectModele() {
      // select modele if the parameter modele is in the url with number
      this.selectoption.forEach((item) => {
        if (item.list[0].slug === this.modele) {
          this.input_selectmodel = this.modele;
        }
      });
    },

    SubmitForm: function (e) {
      var form = document.getElementById("formulaire");
      e.preventDefault();
      const valid = FormValidation(this.scenario, "vueverif");

      // Si le formulaire n'est pas valide, on arrête l'exécution de la fonction
      if (!valid) {
        console.warn("Le formulaire n'est pas valide.");
        return;
      } else {
        //Affichage du component de confirmation si le formulaire est valide

        const confirmComponent = document.querySelector(".confirm");
        confirmComponent.classList.add("active");
      }

      // Création de l'objet FormData
      var formData = new FormData(form);

      //
      let dataObj = {};
      for (var pair of formData.entries()) {
        dataObj[pair[0]] = pair[1];
      }
      // console.table(dataObj);

      fetch(form.action, { method: "post", body: formData }).then(
        (response) => {
          if (response.status === 200) {
            this.image = true;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/form.scss";
</style>
