<template>
  <div class="form subs" id="postform">
    <div class="top-page">
      <picture>
        <img :src="logo" alt="Hyundai logo" />
      </picture>
      <h3 class="title">
        Félicitations, votre demande d'essai a bien été prise en compte.
      </h3>
    </div>
    <form
      @submit.prevent="SubmitForm"
      class="form"
      method="POST"
      action="/send/PostForm.php"
      id="form"
      autocomplete="off"
    >
      <div class="description">
        <p>
          Accordez-nous encore quelques secondes pour mieux comprendre votre
          projet d'achat&nbsp;!
        </p>
      </div>

      <div class="row">
        <fieldset id="input">
          <label for="js_input_periode"
            >Dans quel délai souhaitez-vous réaliser votre projet
            d'achat&nbsp;?</label
          >
          <select id="js_input_periode" v-model="input_periode" name="perdiode">
            <option value="" disabled selected>Sélectionnez une période</option>
            <option value="Moins de 3 mois">Moins de 3 mois</option>
            <option value="Entre 4 et 6 mois">Entre 4 et 6 mois</option>
            <option value="Entre 7 et 12 mois">Entre 7 et 12 mois</option>
            <option value="Plus de 12 mois">Plus de 12 mois</option>
            <option value="Ne sait pas">Ne sait pas</option>
          </select>
        </fieldset>
      </div>
      <div class="row">
        <fieldset id="input">
          <label for="js_input_reprise"
            >Envisagez-vous une reprise de votre véhicule actuel&nbsp;?</label
          >
          <select id="js_input_reprise" v-model="input_reprise" name="reprise">
            <option selected disabled value="">
              Sélectionnez votre reprise
            </option>
            <option value="Oui">Oui</option>
            <option value="Non">Non</option>
          </select>
        </fieldset>
      </div>
      <div class="row">
        <fieldset id="input">
          <label for="js_input_financement"
            >Quel est le type de financement souhaité&nbsp;?</label
          >
          <select
            id="js_input_financement"
            v-model="input_financement"
            name="financement"
          >
            <option selected disabled value="">
              Sélectionnez un financement
            </option>
            <option value="LLD">LLD</option>
            <option value="LOA">LOA</option>
            <option value="Comptant">Comptant</option>
          </select>
        </fieldset>
      </div>
      <div class="row">
        <button id="valid">Valider</button>
      </div>
      <input type="hidden" :value="randuniq" name="randuniq" />
    </form>
  </div>
</template>

<script>
import logo from "../../assets/img/Hyundai_Horizontal_blanc.svg";

export default {
  name: "postForm",

  props: {
    randuniq: String,
  },

  data() {
    return {
      logo: logo,
      periode: "",
      reprise: "",
      financement: "",
      input_financement: "",
      input_periode: "",
      input_reprise: "",
    };
  },

  methods: {
    SubmitForm: function (e) {
      var form = document.getElementById("form");
      e.preventDefault();

      //Affichage du component de confirmation si le formulaire est valide
      const confirmComponent = document.querySelector(".confirm");
      confirmComponent.classList.add("active");

      // Création de l'objet FormData
      var formData = new FormData(form);

      //
      let dataObj = {};
      for (var pair of formData.entries()) {
        dataObj[pair[0]] = pair[1];
      }
      console.table(dataObj);

      fetch(form.action, { method: "post", body: formData }).then((response) =>
        console.log(response)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.top-page {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 30vw;
  padding-bottom: 30px;
}

h3 {
  font-size: 28px;
  color: #ffffff;
  padding-bottom: 30px;
  text-align: center;
}
.form.subs {
  position: fixed;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--MainBlue);
  z-index: 98;
  align-items: center;
  justify-content: center;
  transition: all 1200ms cubic-bezier(0.7, 0, 0, 1);

  select {
    background-color: #ffffff;
  }
}

.active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
}

@keyframes confirm-swipe {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

#form {
  width: 30vw;
  margin: 0 auto;
}

label {
  color: #ffffff;
}

.description {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  padding-bottom: 20px;
}

#input {
  height: 80%;
  display: flex;
  flex-direction: column;
}

#valid {
  border: 1px solid white;
}

label {
  font-size: 16px;
}

@media (max-width: 1500px) {
  #form {
    width: 60vw;
  }
}
</style>
