<template>
  <div class="rgpd">
    <img
      class="mention__close isax"
      src="assets/close-circle.svg"
      @click="DisplayRGPD"
      alt="closeMention"
    />
    <p>
      <strong>Vos préférences</strong><br /><br />
      Vous pouvez modifier en ligne le degré de présence de Hyundai que vous souhaitez dans votre vie.<br />
      Pour modifier ou retirer votre consentement, <a href="https://hyundai-europe-privacy.my.onetrust.com/ui/#/preferences/multipage/login/9e77c974-41a8-427d-b896-0399e53f4efb" >cliquez ici.</a
      ><br /><br />

      ou<br /><br />

      Cliquez sur le lien de désabonnement dans chaque communication écrite que
      nous vous envoyons.<br /><br />
      <em>Remarque : le retrait ou la modification de votre consentement n'affecte
      pas la légalité du traitement fondé sur le consentement avant son retrait
      ou sa modification.</em>
    </p>
  </div>
</template>

<script>
import { ToggleML, ToggleRGPD } from "@/assets/js/ToggleML";

export default {
  name: "RGPD",

  props: {
    slug: String,
  },

  data() {
    return {
      conso: "",
      ml: "",
      pulluscoreUrl: "",
    };
  },

  mounted() {},

  methods: {
    DisplayML() {
      ToggleML();
    },

    DisplayRGPD() {
      ToggleRGPD();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.rgpd {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 65%;
  height: 100%;
  width: 35%;
  background: var(--white);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 0 0);
  //clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
  padding: 25px 25px 25px 25px;
  font-size: 14px;
  transition: clip-path 1000ms cubic-bezier(0.65, 0.27, 0.33, 0.81);
  font-size: 12px;
  display: flex;
  align-items: end;
  flex-direction: column;

  img {
    margin-right: 0;
    margin-bottom: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: $maxMobile) {
    width: 100%;
    left: 0%;
  }

  @media screen and (min-width: $maxMobile) and (max-width: $maxTablette) {
    width: 50%;
    left: 50%;
  }

  @include dark {
    background-color: var(--bgDarkMode);
    color: var(--colorDarkMode);
  }

  &__close {
    text-align: end;
    font-size: 24px;
    margin-bottom: 15px;
    cursor: pointer;
  }
}

.active_rgpd {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
}
</style>
