// Fonction pour vérifier si une expression régulière correspond à une chaîne donnée
function RegexMatch(chaine, regex) {
  return regex.test(chaine);
}

// Fonction exportée pour la validation du formulaire
export default function FormValidation(scenario, goodInputForm) {
  let valideform = true; // Variable pour suivre la validité globale du formulaire
  const verifs = Array.from(document.querySelectorAll(".vueverif")); // Récupère tous les éléments avec la classe 'vueverif' et les convertit en tableau
  const select = Array.from(document.querySelectorAll("select")); // Récupère tous les éléments 'select' et les convertit en tableau

  const checkbox = Array.from(
    document.querySelectorAll('input[type="checkbox"][data-required]')
  ); // Récupère toutes les cases à cocher avec l'attribut 'data-required' et les convertit en tableau

  // Vérifie si toutes les cases à cocher requises sont cochées
  checkbox.forEach((item) => {
    if (item.classList[0] === goodInputForm) {
      if (item.checked) {
        item.parentNode
          .querySelector(".customcheckbox__checkmark")
          .classList.remove("checkerror"); // Supprime la classe 'checkerror' du parent de la case à cocher pour indiquer qu'elle est valide
      } else {
        item.parentNode
          .querySelector(".customcheckbox__checkmark")
          .classList.add("checkerror"); // Ajoute la classe 'checkerror' au parent de la case à cocher pour indiquer qu'elle est invalide
        valideform = false; // Définit la variable 'valideform' sur false pour indiquer que le formulaire est invalide
      }
    }
  });

  //Vérifie si tous les éléments 'select' sont remplis
  select.forEach((item) => {
    //console.log(item, goodInputForm);
    if (item.classList[0] === goodInputForm) {
      // Ajoute la classe 'error' ou 'valid' à l'élément pour indiquer si il est invalide
      if (item.value === "") {
        item.classList.add("error");
        //item.parentNode.classList.add('errorfield');
        item.classList.remove("valid");
        //item.parentNode.classList.remove('validfield');
        valideform = false; // Définit la variable 'valideform' sur false pour indiquer que le formulaire est invalide
      } else {
        item.classList.add("valid");
        //item.parentNode.classList.add('validfield');
        item.classList.remove("error");
        //item.parentNode.classList.add('errorfield');
      }
    }
  });

  // Vérifie si tous les éléments de texte et de nombre sont remplis et correspondent aux expressions régulières
  verifs.forEach((item) => {
    if (item.classList[0] === goodInputForm) {
      let value = item.value; // Récupère la valeur de l'élément
      let regex = new RegExp(item.dataset.regex); // Récupère l'expression régulière à partir de l'attribut 'data-regex' de l'élément
      const match = RegexMatch(value, regex); // Vérifie si la valeur correspond à l'expression régulière
      //console.log(match, value);
      // Ajoute la classe 'error' ou 'valid' à l'élément pour indiquer s'il est invalide
      if (match === true && value !== "") {
        item.classList.add("valid");
        item.classList.remove("error");
      } else {
        item.classList.add("error");
        item.classList.add("error");
        item.classList.remove("valid");
        valideform = false;
      }
    }
  });

  // Affiche un message dans la console pour indiquer que l'envoi des données peut commencer ou non
  if (valideform === true) {
    return true;
  } else {
    return false;
  }
}
