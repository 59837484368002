<template>
  <div class="slider">
    <div class="slide" :style="cssProps">
      <div v-if="uniqueoffre === false" class="slide__title">
        <h1 v-if="carname">{{ carname }}</h1>
        <h2 v-if="claim">{{ claim }}</h2>
      </div>
      <div v-if="isVideo" class="slide__video">
        <video id="js_BgVideo" autoplay playsinline loop muted>
          <source :src="videosrc" type="video/webm" />
          Votre navigateur ne supporte pas la lecture de vidéos HTML5.
        </video>
      </div>
    </div>
    <div v-if="uniqueoffre === false && offreUrl" class="slide__offre">
      <img :src="offreUrl" alt="Offre" width="auto" height="auto" />
    </div>
  </div>
</template>

<script>
import stores from "@/assets/js/store/store";
import { useApiStore } from "@/pinia/api";

export default {
  name: "Slide",

  props: {
    carname: String,
    claim: String,
    imgUrl: String,
    offre: String,
    uniqueoffre: Boolean,
    ml: Array,
    pl: Array,
  },

  data() {
    return {
      api: useApiStore(),
      num: 0,
      offreUrl: "",
      bgUrl: "",
      isVideo: false,
      videosrc: "",
    };
  },

  computed: {
    cssProps() {
      return {
        "--background-image-url": "url(" + this.bgUrl + ")",
      };
    },
  },

  mounted() {
    this.Fetchdata();
    //console.log(this.ml);
    if (this.ml !== undefined) {
      stores.mentions.push(this.ml);
    }
    if (this.pl !== undefined) {
      stores.pl.push(this.pl);
    }
  },

  methods: {
    Fetchdata() {
      this.GetAllBlocOffres();

      //SI LE FICHIER N'EST PAS UNE VIDEO
      if (!this.imgUrl.endsWith(".mp4") && !this.imgUrl.endsWith(".webm")) {
        //IMG BG SLIDE
        if (this.imgUrl !== "assets/slide_default.jpg") {
          this.bgUrl = this.api.baseUrl + "/i?" + this.imgUrl;
        } else {
          //console.log(this.imgUrl);
          this.bgUrl = process.env.BASE_URL + this.imgUrl;
        }
      }
      //SI LE FICHIER EST UNE VIDEO
      else {
        this.isVideo = true;

        this.videosrc = this.api.baseUrl + "/i?" + this.imgUrl;
        let player = document.getElementById("js_BgVideo");
        if (player !== null) {
          player.load();
        }
      }
    },

    GetAllBlocOffres() {
      let year = new Date().getFullYear();
      let month = ("0" + (new Date().getMonth() + 1)).slice(-2);

      var myHeaders = new Headers();
      myHeaders.append("xc-api-token", this.api.xcToken);
      myHeaders.append("x-table-id", this.api.Offers);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      let param = encodeURIComponent("(date,eq," + year + month + ")");

      fetch(this.api.baseUrl + "?where=" + param, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          //console.log(result.files)
          let final = result;
          let blocList = final.list;

          //GENERER URL DU BLOC OFFRE
          let filename = this.offre;
          blocList[0].files.forEach((item) => {
            if (item.title === filename) {
              this.offreUrl = this.api.baseUrl + "/i?" + item.path;
            }
          });
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/variables.scss";

.slide {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: var(--background-image-url);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @media screen and (max-width: $maxMobile) {
    height: 47vh;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.54) 0%,
      rgba(0, 0, 0, 0) 25.39%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__title {
    color: var(--white);
    padding: var(--baseUnit);
    z-index: 2;
    position: absolute;
    h1 {
      font-size: 2.5vw;
      text-shadow: 1px 1px #000;
      font-family: "HyundaiMedium";
      font-weight: 500;
    }

    h2 {
      font-family: "HyundaiLight";
      font-size: 1.5vw;
      text-shadow: 1px 1px #000;
    }

    @media screen and (max-width: $maxMobile) {
      h1 {
        font-size: 7vw;
      }

      h2 {
        font-size: 4vw;
      }
    }
  }

  &__offre {
    position: absolute;
    background-color: var(--MainBlue);
    bottom: 13vh;
    left: 0px;
    z-index: 2;
    color: #fff;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;

    img {
      width: 260px;
      height: auto;
      min-width: 160px;
    }

    @media screen and (max-width: $maxMobile) {
      width: 100%;
      height: 30%;
      position: initial;

      img {
        max-height: 125px;
        width: auto;
      }
    }
  }

  &__video {
    position: absolute;
    background: #000;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -2;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: $maxMobile) {
      height: 100%;
    }
  }
}
</style>
@/assets/js/store/store
