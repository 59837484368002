<template>
  <div class="mention">
    <div class="mention__garantie">
      <picture>
        <source
          srcset="assets/garantie_dark.svg"
          media="(prefers-color-scheme:dark)"
        />
        <img
          class="garantie"
          src="assets/garantie.svg"
          alt="Hyundai"
          width="150"
          height="150"
        />
      </picture>
      <img
        class="mention__close isax"
        src="assets/close-circle.svg"
        @click="DisplayML"
        alt="closeMention"
      />
    </div>
    <div v-if="uniqueoffre === false">
      <p class="mention__conso" v-for="item in conso" :key="item.id">
        <strong><span v-html="item"></span></strong><br /><br />
      </p>
    </div>
    <p v-else class="mention__conso">
      <strong><span v-html="conso[0]"></span></strong><br /><br />
    </p>
    <div v-if="uniqueoffre === false">
      <p class="mention__ml" v-for="item in mlarr" :key="item.id">
        <span v-html="item"></span><br /><br />
      </p>
    </div>

    <p class="mention__ml" v-else>
      <span v-html="mlarr[0]"></span><br /><br />
    </p>

    <p>
      (*)La garantie 5 ans kilométrage illimité de Hyundai s'applique uniquement
      aux véhicules Hyundai vendus initialement par un Distributeur Agréé
      officiel Hyundai à un client final, conformément aux termes et conditions
      du carnet de Garantie Entretien & Assistance du véhicule. Détails sur
      Hyundai.fr.<br /><br />
      (**)Les batteries haute-tension de nos véhicules électrifiés sont
      garanties 8 ans ou 160 000 km, Voir détails sur hyundai.fr.
    </p>
    <br /><br />
    <p class="mention__loi">
      Au quotidien, prenez les transports en commun #SeDéplacerMoinsPolluer
    </p>
    <div class="mention__polluscore" v-if="uniqueoffre === false">
      <div v-for="(imageUrl, index) in pulluscoreUrl" :key="index">
        <img
          :src="imageUrl"
          alt="Polluscore"
          class="polluscore"
          width="150"
          height="150"
        />
      </div>
    </div>
    <div class="mention__polluscore" v-else>
      <div>
        <img
          :src="pulluscoreUrl[0]"
          alt="Polluscore"
          class="polluscore"
          width="150"
          height="150"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleML, ToggleRGPD } from "@/assets/js/ToggleML";
import axios from "axios";

import stores from "@/assets/js/store/store";
import MLFormating from "@/assets/js/MLFormat";
import { useApiStore } from "@/pinia/api";

export default {
  name: "Mention",

  props: {
    slug: String,
    slideSlug: Array,
    uniqueoffre: Boolean,
    polluscore: Array,
  },

  watch: {
    slug(newVal, oldVal) {
      if (this.slug !== "") {
        this.ManageML();
      }
    },

    slideSlug(newVal, oldVal) {
      this.ManageML();
    },
  },

  data() {
    return {
      api: useApiStore(),
      conso: [],
      ml: "",
      mlarr: [],
      pulluscoreUrl: [],
      slugBool: true,
      modele: {
        polluscore: "",
        title: "",
      },
      saveML: "",
      saveConso: "",
    };
  },

  mounted() {
    //this.ManageML();
  },

  methods: {
    DisplayML() {
      ToggleML();
    },

    async GetAllMention() {
      let year = new Date().getFullYear();
      let month = ("0" + (new Date().getMonth() + 1)).slice(-2);

      var myHeaders = new Headers();

      let param = encodeURIComponent("(date,eq," + year + month + ")");
      const response = await axios.get(this.api.baseUrl + "?where=" + param, {
        headers: {
          "x-table-id": this.api.ML,
          "xc-api-token": this.api.xcToken,
        },
      });
      const result = response;

      let final = result.data.list;
      stores.mllist = final;
    },

    getPolluscore(id) {
      axios
        .get(this.api.baseUrl, {
          headers: {
            "xc-api-token": this.api.xcToken,
            "x-table-id": this.api.Modeles,
          },
        })
        .then((res) => {
          let finalUrl = "";

          res.data.list.forEach((item) => {
            if (id !== undefined) {
              id.forEach((id) => {
                if (item.slug === id) {
                  this.modele.polluscore = item.polluscore;
                  this.modele.title = item.title;

                  // CRÉATION DU POLLUSCORE EN FONCTION DU DARK MODE OU NON

                  if (
                    window.matchMedia &&
                    window.matchMedia("(prefers-color-scheme: dark)").matches
                  ) {
                    finalUrl =
                      "https://cdn.hyundai.media/polluscore/png/?val=" +
                      this.modele.polluscore +
                      "&ligne1=" +
                      this.modele.title +
                      "&ligne2=" +
                      "" +
                      "&width=1000&color=white";
                  } else {
                    finalUrl =
                      "https://cdn.hyundai.media/polluscore/png/?val=" +
                      this.modele.polluscore +
                      "&ligne1=" +
                      this.modele.title +
                      "&ligne2=" +
                      "" +
                      "&width=1000&color=black";
                  }
                  this.pulluscoreUrl.push(finalUrl);
                }
              });
            }
          });
        });
    },

    async ManageML() {
      this.pulluscoreUrl = [];
      if (this.slug !== "") {
        if (stores.mllist.length === 0) {
          await this.GetAllMention();
        }
        if (this.uniqueoffre === true) {
          this.saveML = this.mlarr[0];
          this.saveConso = this.conso[0];
        }
        this.slugBool = true;
        stores.mllist[0].json.forEach((item) => {
          if (item.id === this.slug) {
            this.mlarr = [];
            this.conso = [];
            this.conso.push(MLFormating(item.conso));
            this.mlarr.push(MLFormating(item.ml));

            this.getPolluscore([item.id]);
          }
        });
      } else if (this.slideSlug !== undefined) {
        this.ml = "";

        if (this.uniqueoffre === false || this.slugBool === true) {
          this.conso = [];
          this.mlarr = [];
          this.slugBool = false;
        }

        if (
          this.uniqueoffre === true &&
          this.saveML !== "" &&
          this.saveConso !== ""
        ) {
          this.mlarr = [];
          this.conso = [];
          this.mlarr.push(this.saveML);
          this.conso.push(this.saveConso);
        }

        if (stores.mllist.length === 0) {
          await this.GetAllMention();
        }
        let num = 0;
        stores.mllist[0].json.forEach((item) => {
          this.slideSlug.forEach((slide) => {
            if (item.id === slide) {
              var texteModifie = item.ml.replace(
                /^(?:\([^)]+\))+/gm,
                function (match, p1) {
                  // Convertit la correspondance en nombre

                  // Incrémente le nombre
                  num++;

                  // Retourne le nombre incrémenté sous forme de chaîne
                  return "(" + num.toString() + ")";
                }
              );

              this.conso.push(MLFormating(item.conso));
              this.mlarr.push(MLFormating(texteModifie));
            }
          });
        });
        this.getPolluscore(this.polluscore);
      }
    },

    CutPolluName(str) {
      if (str.length > 20) {
        const firstSpace = str.indexOf(" ");
        const secondSpace = str.indexOf(" ", firstSpace + 1);
        if (secondSpace !== -1) {
          const firstPart = str.substring(0, secondSpace);
          const secondPart = str.substring(secondSpace + 1);
          return [firstPart, secondPart];
        } else {
          return [str, ""];
        }
      } else {
        return [str, ""];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.mention {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 65%;
  height: 100%;
  background: var(--white);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 0 0);
  padding: 25px 25px 25px 25px;
  font-size: 14px;
  transition: clip-path 1000ms cubic-bezier(0.65, 0.27, 0.33, 0.81);
  font-size: 12px;
  overflow-y: scroll;

  @media screen and (max-width: $maxMobile) {
    left: 0%;
  }

  @media screen and (min-width: $maxMobile) and (max-width: $maxTablette) {
    left: 50%;
  }

  @include dark {
    background-color: var(--bgDarkMode);
    color: var(--colorDarkMode);
  }

  &__garantie {
    display: flex;

    picture {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__garantie > img {
    position: absolute;
    right: 5%;
  }

  &__polluscore {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;

    div {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: left;

      img {
        height: fit-content;
        width: 100%;
        max-width: 150px;
      }
    }
  }

  &__close {
    text-align: end;
    font-size: 24px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  &__conso {
    font-family: "HyundaiMedium";
    font-weight: 500;
  }

  &__loi {
    color: var(--white);
    background-color: black;
    padding: 10px;
  }

  .polluscore {
    margin-top: 50px;

    @media screen and (max-width: $maxMobile) {
      margin-top: 25px;
      max-width: 150px;
    }
  }

  .garantie {
    margin-bottom: var(--baseUnit);

    @media screen and (max-width: $maxMobile) {
      margin-top: 25px;
      max-width: 200px;
    }
  }
}

.active_mention {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
}
</style>
