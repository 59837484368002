import URLParse from "url-parse";

function ManageSelectModel() {
  const modelParam = new URLParse(window.location.href, true).query.model;
  if (modelParam !== undefined) {
    return modelParam;
  }
}

export default { ManageSelectModel };
