var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Loader'),_c('Confirm',{attrs:{"message":_vm.message}}),(_vm.postFormValidation)?_c('postForm',{attrs:{"randuniq":_vm.randuniq}}):_vm._e(),_c('div',{staticClass:"home__slider"},[(!_vm.UnvalidUrl)?_c('div',[(
          _vm.Maindata.slides &&
          _vm.Maindata.slides[0] &&
          _vm.Maindata.slides[0].unique === true
        )?_c('div',{staticClass:"home__slider__title"},[_c('h1',[_vm._v(_vm._s(_vm.Maindata.slides?.[0]?.titleSlider))]),_c('h2',[_vm._v(_vm._s(_vm.Maindata.slides?.[0]?.claimSlider))])]):_vm._e()]):_vm._e(),(_vm.UnvalidUrl)?_c('swiper-container',{staticClass:"home__slider__swiper swiper-container",attrs:{"slides-per-view":"1","speed":"500","loop":"true","navigation":"true","autoplay":"false","pauseOnMouseEnter":"false"}},[_c('swiper-slide',[_c('Slide',{attrs:{"carname":"","claim":"","offre":"","imgUrl":"assets/slide_default.jpg","uniqueoffre":""}})],1)],1):_vm._e(),(!_vm.UnvalidUrl && _vm.Maindata.slides && _vm.Maindata.slides[0])?_c('swiper-container',{staticClass:"home__slider__swiper swiper-container",attrs:{"slides-per-view":1,"pagination":true,"speed":700,"loop":true,"navigation":true,"autoplay":false,"initial-slide":_vm.initialSlide},on:{"slidechange":_vm.emitSlide}},_vm._l((_vm.Maindata.slides),function(item,index){return _c('swiper-slide',{key:index,attrs:{"data-fixed-index":index}},[(
            item.titleSlider !== undefined && item.claimSlider !== undefined
          )?_c('Slide',{attrs:{"carname":item.titleSlider,"claim":item.claimSlider,"offre":item.offersSlider,"imgUrl":item.image,"ml":item.mentions_legaleSlider,"uniqueoffre":_vm.Maindata.slides?.[0]?.unique,"pl":item.polluscoreSlider}}):_c('Slide',{attrs:{"carname":"","claim":"","offre":"","imgUrl":item.image,"uniqueoffre":_vm.Maindata.slides?.[0]?.unique}})],1)}),1):_vm._e(),(_vm.Maindata.slides?.[0]?.unique === true && _vm.offreFixedUrl)?_c('div',{staticClass:"home__slider__offre"},[_c('img',{attrs:{"src":_vm.offreFixedUrl,"alt":"Offre","width":"auto","height":"auto"}})]):_vm._e(),(!_vm.UnvalidUrl)?_c('div',{staticClass:"home__slider__conditions",on:{"click":_vm.DisplayML}},[_c('p',[_vm._v("Voir conditions")])]):_vm._e()],1),_c('div',{staticClass:"home__formsection"},[(
        _vm.Maindata.formulaire &&
        _vm.Maindata.formulaire[0] &&
        _vm.Maindata.formulaire[0].form === 'FormLocal'
      )?_c('FormMap',{attrs:{"data":{ Maindata: _vm.Maindata },"scenario":_vm.scenario,"utmSource":_vm.utmSource,"utmParteners":_vm.utmParteners,"sapParam":_vm.sapParam,"modele":_vm.modele,"postformValid":_vm.postFormValidation,"error":_vm.error,"optin":_vm.optin,"randuniq":_vm.randuniq,"formParams":_vm.formParams}}):(
        _vm.Maindata.formulaire &&
        _vm.Maindata.formulaire[0] &&
        _vm.Maindata.formulaire[0].form === 'FormB2C'
      )?_c('FormBasique',{attrs:{"data":{ Maindata: _vm.Maindata },"error":_vm.error,"scenario":_vm.scenario,"utmSource":_vm.utmSource,"utmParteners":_vm.utmParteners,"modele":_vm.modele,"postformValid":_vm.postFormValidation,"optin":_vm.optin,"randuniq":_vm.randuniq,"formParams":_vm.formParams}}):(
        _vm.Maindata.formulaire &&
        _vm.Maindata.formulaire[0] &&
        _vm.Maindata.formulaire[0].form === 'FormB2B'
      )?_c('FormB2B',{attrs:{"data":{ Maindata: _vm.Maindata },"modele":_vm.modele,"postformValid":_vm.postFormValidation,"utmSource":_vm.utmSource,"utmParteners":_vm.utmParteners,"error":_vm.error,"optin":_vm.optin,"randuniq":_vm.randuniq,"formParams":_vm.formParams}}):(
        _vm.Maindata.formulaire &&
        _vm.Maindata.formulaire[0] &&
        _vm.Maindata.formulaire[0].form === 'FormB2B_Local'
      )?_c('FormB2BLocal',{attrs:{"data":{ Maindata: _vm.Maindata },"sapParam":_vm.sapParam,"modele":_vm.modele,"postformValid":_vm.postFormValidation,"utmSource":_vm.utmSource,"utmParteners":_vm.utmParteners,"error":_vm.error,"optin":_vm.optin,"randuniq":_vm.randuniq,"formParams":_vm.formParams}}):_c('FormBasiqueError',{attrs:{"error":true,"scenario":_vm.scenario,"modele":_vm.modele,"postformValid":_vm.postFormValidation,"utmSource":_vm.utmSource,"utmParteners":_vm.utmParteners,"optin":_vm.optin,"randuniq":_vm.randuniq,"formParams":_vm.formParams}}),(!_vm.UnvalidUrl)?_c('Mention',{ref:"mentionComponent",attrs:{"slug":_vm.stores.slug_mention,"slideSlug":_vm.stores.mentions[this.indexData],"uniqueoffre":_vm.Maindata.slides?.[0]?.unique,"polluscore":_vm.stores.pl[this.indexData]}}):_vm._e(),(_vm.UnvalidUrl)?_c('Mention',{ref:"mentionComponent",attrs:{"slug":_vm.stores.slug_mention,"polluscore":_vm.stores.pl[this.indexData]}}):_vm._e(),_c('RGPD')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }